.slide-main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 4;
  padding-bottom: 50px;
  .image-wrapper {
    display: flex;
    width: 60%;
    height: auto;
    > img {
      position: fixed;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 60%;
      height: auto;
      z-index: 5;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    color: white;
    display: flex;
    > div {
      margin-top: 20vh;
      display: flex;
      flex-direction: column;
      position: fixed;
    }
    .text-icons {
      display: flex;
      > div {
        margin-top: 20px;
        margin-bottom: 20px;
        > svg {
          font-size: 4rem;
          padding: 20px;
        }
        .react {
          animation: bounce 2s ease infinite;
        }
        .angular {
          animation: bounce2 4s ease infinite;
        }
        .node {
          animation: bounce 3s ease infinite;
        }
        .vue {
          animation: bounce 5s ease infinite;
        }
        .git {
          animation: bounce2 4s ease infinite;
        }
      }
    }
    .text-text {
      font-size: 1.3rem;
      // margin-right: 30px;
    }
  }
}

.slide-introduction {
  display: flex;
  min-height: 100vh;
  background-color: #1f2240;
  width: 100%;
  z-index: 6;
}

.divider {
  width: 100%;
  height: 200px;
  z-index: 6;
}

.slide-learning {
  display: flex;
  min-height: 100vh;
  background-color: #161933;
  width: 100%;
  height: 100%;
}

.text-info {
  font-size: 1.7rem;
  color: white;
  font-family: "RocknRollOne-Regular";
  margin-bottom: 30px;
}

@media only screen and (min-width: 391px) and (max-width: 1024px) {
  .slide-main {
    flex-direction: column;
    margin-top: 20px;
    min-height: 100vh;
    .image-wrapper {
      min-height: 200px;
      width: 100%;
    }
    .text-wrapper {
      min-height: 200px;
      width: 100%;
      z-index: 20;
      > div {
        padding: 10px;
        position: fixed;
        margin-top: 0;
        .text-heading {
          font-size: 1.8rem !important;
        }
        > span {
          font-size: 1.6rem !important;
        }
      }
    }
    .text-icons {
      display: flex;
      > div {
        margin: auto;
        > svg {
          font-size: 2rem !important;
          padding: 20px;
        }
      }
    }
    .text-text {
      font-size: 1rem;
      // margin-right: 30px;
    }
  }
  .text-info {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  .divider {
    height: 100px;
  }
}

@media only screen and (max-width: 390px) {
  .slide-main {
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 100px;
    .image-wrapper {
      min-height: 200px;
      width: 100%;
    }
    .text-wrapper {
      min-height: 200px;
      height: 100%;
      width: 100%;
      display: flex;
      width: 100%;
      z-index: 20;
      > div {
        padding: 10px;
        position: fixed;
        margin-top: 0;
        .text-heading {
          font-size: 1.8rem !important;
        }
        > span {
          font-size: 1.6rem !important;
        }
      }
    }
    .text-icons {
      display: flex;
      > div {
        margin: auto;
        > svg {
          font-size: 1.5rem !important;
          padding: 20px;
        }
      }
    }
    .text-text {
      font-size: 1rem;
      //margin-right: 30px;
    }
  }
  .text-info {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  .divider {
    height: 100px;
  }
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-12px);
  }
}

@keyframes bounce2 {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-40px);
  }
  60% {
    transform: translateY(-25px);
  }
}
