.header {
  position: fixed;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: 100px;
  grid-column-gap: 0px;
  grid-row-gap: 3px;
  justify-self: center;
  width: 100%;
  z-index: 9997;
  padding: 5px;
  font-family: "RocknRollOne-Regular";
  box-shadow: 1px 2px rgba(76, 79, 119, 0.2);
}

.light {
  background-color: white;
  color: #4c4f77;
}

.dark {
  background-color: #161933;
  color: white;
}

.menu-list { 
  grid-area: 1 / 3 / 1 / 7; 
  display: inline-flex;
  justify-content: space-around;
  white-space: nowrap;
  > div {
    display: flex;
    cursor: pointer;
    margin: auto;
  }
}

.nav-menu-drawer {
  display: none;
}

// You can use the grid-area property to specify where to place an item.
// The syntax is grid-row-start / grid-column-start / grid-row-end / grid-column-end.
.logo-div { 
  grid-area: 1 / 1 / 2 / 1; 
  place-self: center;
  display: flex;
  > img {
    max-height: 80px;
    margin: auto;
  }
}

.login-wrapper {
  grid-area: 1 / 8 / 1 / 8;
  display: flex;
  height: 100%;
  .icon-login { 
    margin: auto;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    border: none;
    > svg {
      color: #e15345;
    }
  }
  .login-button {
    margin: auto;
    > button {
      height: 24px;
      background-color: #e15345;
      border: 1px solid #e15345;
      border-radius: 6px;
      color: white;
      font-family: "RocknRollOne-Regular";
      font-weight: 300;
      cursor: pointer;
      border: none;
    }
  }
}


.icon-basket { 
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  > div {
    font-size: 1.8rem;
    color: #fecd20;
  }
  > span {
    height: 20px;
    font-size: 1.1rem;
    text-align: center;
    white-space: nowrap;
  }
  
}

// switch

.switch-wrapper {
  grid-area: 1 / 8 / 1 / 8; 
  display: flex;

  .switch {
    margin: auto;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #4c4f77;
    .moon {
      margin-left: 12px;
      font-size: 13px;
    }
    .sun {
      margin-left: 6px;
      font-size: 14px;
    }
    > svg {
      color: yellow;
      
    }
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #4c4f77;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #4c4f77;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}




//MEDIA QUERIES FÜR IPAD – PORTRAIT & LANDSCAPE

@media all and (max-width: 1300px) and (min-width: 813px){

  .logo-div { 
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    > img {
      max-height: 75px;
      margin: auto;
    }
  }

  .icon-login { 
    > div {
      font-size: 2rem;
    }
  }
  
  .icon-basket { 
    > div {
      font-size: 2rem;
    }
    > span {
      line-height: 1.5rem;
      font-size: 1rem;
    }
  }

  .menu-list { 
    grid-area: 1 / 3 / 1 / 7; 
    font-size: 1rem;
  }

  .nav-menu-drawer {
    display: grid;
    grid-area: 2 / 1 / 3 / 2; 
    place-self: center;
    > button {
      border: none;
      background-color: transparent;
      color: #4c4f77;
      font-size: 20px;
    }
  }
  
}


@media all and (max-width: 812px) and (min-width: 375px){
 
  .logo-div { 
    grid-area: 1 / 2 / 1 / 2;
  }
  .switch-wrapper {
    grid-area: 1 / 7 / 1 / 7; 
  }

  .icons-wrapper {
    flex-direction: row-reverse;
  }
  .login-wrapper {
    place-self: start;
  }

  .icon-login {
    font-size: 14px;
  }
  
  .icon-basket { 
    font-size: 14px;
  }

  .menu-list { 
    grid-area: 2 / 1 / 2 / 9; 
    font-size: 1rem;
  }

  .time-info-div {
    display: none;
  }

  .contact-info-div {
    display: none;
  }
  
}

@media all and (max-width: 375px){
 
  .logo-div { 
    grid-area: 1 / 2 / 1 / 2;
  }
  .switch-wrapper {
    grid-area: 1 / 7 / 1 / 7; 
  }

  .icons-wrapper {
    flex-direction: row-reverse;
  }
  .login-wrapper {
    place-self: start;
  }

  .icon-login { 
    font-size: 14px;
  }
  
}
  
