.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #4c4f77;
  background-color: #1f2240;
  display: flex;
  .wrapper {
    margin: auto;
    > svg {
      font-size: 40px;
      margin-right: 8px;
      color: white;
      z-index: 12 !important;
      cursor: pointer;
      position: relative;
    }
  }
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}