.slide-main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 80%;
  margin: auto;
  z-index: 4;
  padding-bottom: 50px;
  .job-header {
    font-family: "RocknRollOne-Regular";
    font-size: 1.7rem;
    margin: auto;
    margin-top: 10px;
  }
  .job-title {
    font-size: 1.4rem;
    margin: auto;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .job-tech-stack {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    > div {
      font-size: 1.1rem;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      > svg {
        margin-right: 10px;
      }
    }
    .header {
      font-family: "RocknRollOne-Regular";
      font-size: 1.4rem;
    }
  }
  .job-responsibility {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    > div {
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      > svg {
        margin-right: 10px;
      }
    }
    .header {
      font-family: "RocknRollOne-Regular";
      font-size: 1.4rem;
    }
  }
}